.events_table
  margin: 2rem 0
  td
    padding: .65rem !important
    min-width: min-content !important
    svg
      margin: 0 !important
      vertical-align: middle !important

  tr
    transition: all ease .3s
  tr:hover
    background-color: var(--c_select_bg)

.link-itm-table
  tr
    transition: all ease .3s
  tr:hover
    background-color: var(--c_select_bg)
  td:first-child
    padding-left: 10px

.rsm-geographies
  path
    pointer-events: none

.geoPoint
  + text
    display: none
  &:hover
    + text
      display: block

.rsm-marker
  &:hover
    z-index: 100000

#EventsGeoChart
  svg
    border: 1px solid #ccc
    background: #fff

