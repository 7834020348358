.invited_users
	&__wrapper

		.controlls
			gap: 10px

			.prolong
				background: var(--c_main)
				svg
					path
						stroke: #fff
