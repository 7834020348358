/* header begin */
.header {
	width: 100%;
	height: 90px;
	position: fixed;
	z-index: 12;
	background: #FFFFFF;
	color: $c_gray;
	box-shadow: 0px -1px 30px rgba(80, 56, 236, 0.3);
	transition: transform .3s ease-in-out;
	will-change: transform;

	&--hide {
		transform: translateY(-100%);
	}

	&-content {
		position: relative;
		display: flex;
		align-items: center;
		height: 90px;
		justify-content: space-between;

		&__right, &__left {
			display: flex;
			align-items: center;
			gap: 30px;
		}
	}

	&-link {
		position: relative;
		text-decoration: none;
		display: flex;
		align-items: baseline;
		gap: 8px;
		color: inherit;
		transition: all .2s ease-in-out;
		z-index: 1;

		&.with-arrow {
			align-items: center;
		}

		svg {
			fill: $c_gray_bg;
			transition: all .2s ease-in-out;
		}

		a {
			color: $c_gray;

			&:hover{
				color: $c_second;
			}
		}

		&:hover {
			color: $c_second;

			svg {
				fill: $c_second;
			}

			.header-link-sub {
				opacity: 1;
				pointer-events: auto;
			}
		}

		.pin {
			position: relative;
			left: -6px;
			top: -12px;
			width: 24px;
			height: 24px;
			line-height: 27px;
			text-align: center;
			border-radius: 50%;
			font-size: $fs_small;
			background: $c_gray_bg;
			color: #fff;
			font-weight: 300;

			&.active {
				background: #FF5C00;
			}
		}

		&-sub {
			position: absolute;
			left: -14px;
			top: -14px;
			min-width: 220px;
			background: #fff;
			box-shadow: 0px 10px 21px rgba(111, 90, 173, 20%);
			padding: 40px 13px 2px;
			z-index: -1;
			border-radius: 8px;
			opacity: 0;
			transition: all 0.3s ease-in-out;
			pointer-events: none;
			list-style: none;

			a {
				position: relative;
				padding: 12px 0;
				display: block;
				text-decoration: none;

				&:after {
					content: '';
					position: absolute;
					bottom: 0;
					left: -5px;
					right: -5px;
					border-bottom: 1px solid rgba(179, 179, 179, 0.3);
				}

				&:last-child:after {
					display: none;
				}
			}
		}
	}

	&-ico {
		background: none;
		border: none;
		outline: none;
		cursor: pointer;

		svg {
			fill: $c_gray_bg;
			transition: all .2s ease-in-out;
		}

		&:hover {
			svg {
				fill: $c_second;
			}
		}
	}

	&-user {
		position: relative;
		color: $c_gray;
		text-decoration: none;
		transition: all .3s ease-in-out;
		padding: 13px;
		z-index: 1;

		a:hover {
			color: $c_second;
		}

		&:after {
			content: '';
			position: relative;
			top: 3px;
			margin-left: 6px;
			display: inline-block;
			vertical-align: middle;
			border: 5px solid transparent;
			border-top: 5px solid $c_gray;
		}

		&:hover {
			.header-user-list {
				opacity: 1;
				pointer-events: auto;
			}
		}

		&-title {
			color: inherit;
			text-decoration: none;
		}

		&-link{
			position: relative;
			color: inherit;
			padding: 12px 0;
			display: block;
			text-decoration: none;
			cursor: pointer;

			&:after {
				content: '';
				position: absolute;
				bottom: 0;
				left: -5px;
				right: -5px;
				border-bottom: 1px solid rgba(179, 179, 179, 0.3);
			}

			&:last-child:after {
				display: none;
			}

			&:hover{
				color: $c_second;
			}
		}

		&-list {
			position: absolute;
			left: 0;
			top: 0;
			min-width: 170px;
			background: #fff;
			box-shadow: 0px 10px 21px rgba(111, 90, 173, 0.2);
			padding: 40px 13px 2px;
			z-index: -1;
			border-radius: 8px;
			opacity: 0;
			transition: all .3s ease-in-out;
			pointer-events: none;

			button {
				text-decoration: none;
				text-align: left;
				display: block;
				position: relative;
				width: 100%;
				padding: 12px 0;
			}
		}
	}

	&-wrapper {
		height: 90px;
	}

	&__logo {
		--logo-color1: #5038EC;
		--logo-color2: #FF9500;

		&:hover {
			--logo-color2: #5038EC;
			--logo-color1: #FF9500;
		}
	}

	&-menu {
		display: flex;
		list-style: none;
		flex: 1;

		li {
			margin-right: 25px;

			a {
				color: inherit;
				text-decoration: none;
			}
		}
	}

	&-setting {
		position: absolute;
		top: 100%;
		background: #FFFFFF;
		box-shadow: 0px 10px 21px rgba(111, 90, 173, 0.2);
		border-radius: 8px;
		padding: 30px 40px;
		left: 50%;
		transform: translateX(-50%) translateY(-10px);
		margin-top: 15px;
		opacity: 0;
		pointer-events: none;
		transition: all .2s ease-in-out;
		font-size: 16px;

		&.active {
			pointer-events: auto;
			opacity: 1;
			transform: translateX(-50%) translateY(0);

		}

		&-itm {
			display: flex;
			align-items: center;
			color: $c_gray;

			&:not(:last-child) {
				padding-bottom: 22px;
				border-bottom: 1px solid rgba(179, 179, 179, 0.3);
			}

			&:not(:first-child) {
				padding-top: 22px;
			}

			&__title {
				min-width: 230px;
			}

			&__itms {
				display: flex;
				align-items: center;
				gap: 16px;
				height: 50px;
			}

			&__btn {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 50px;
				padding: 0 13px;
				background: #EFEFFF;
				border-radius: 8px;
				font-weight: 700;
				font-size: $fs_title;
				line-height: 24px;
				box-sizing: border-box;
				cursor: pointer;


				&.setting-size-normal {
					width: 53px;
					font-size: 1em;
				}

				&.setting-size-mid {
					width: 53px;
				}

				&.setting-theme-dark {
					color: #fff;
					background: $c_gray;
				}

				&.setting-size-big {
					width: 53px;
					font-size: $fs_big_title;
				}

				&:hover, &.active {
					background: $c_purple;
					color: #fff;
				}
			}
		}
	}

}

@media all and (max-width: 1065px) {
	.link-to-talisman {
		display: none;
	}
}