.main-info-list {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 20px;
	padding: 30px 40px;
	border-radius: 0.5rem;
	box-shadow: 0rem 0.25rem 0.25rem 0rem rgba(0, 0, 0, 0.25);
	background: url("../img/mine/stat_bg.png") no-repeat center;
	background-size: cover;
}

.main-info-itm {
	position: relative;
	border-radius: 8px;
	overflow: hidden;
	z-index: 1;
	min-height: 48px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	padding: 0 35px 0 0;
	gap: 20px;
	color: var(--c_main_dark);
	--fill_color: var(--c_main_dark);

	&:last-child{
		padding: 0 0 0 35px;
	}

	&__bg {
		@include fill();
		background-position: center;
		background-size: cover;
		z-index: -1;
	}

	&__ico {
		width: 32px;

		svg {
			fill: var(--fill_color);
			max-width: 100%;
		}

		img {
			max-width: 100%;
		}
	}

	&__num {
		font-size: var(--fs_title_mid);
	}

	&__info {
		flex: 1;

		&__name{
			&.title{
				margin-bottom: 0;
			}
		}

		&__desc {
			font-size: $fs_small;
			font-weight: 300;
		}
	}
}
.main_buttons{
	.col{
		width: 45%;
	}
}

.list-block {
	position: relative;
	background: #FFFFFF;
	box-shadow: 0px 10px 21px rgba(111, 90, 173, 0.2);
	border-radius: 8px;
	padding: 44px;

	&-wrap {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 20px;
		margin: 70px 0 50px;
	}

	&-content {
		margin: 35px 0 107px;
	}

	&__link {
		position: absolute;
		color: $c_main;
		text-decoration: none;
		bottom: 38px;
		left: 44px;
		transition: all .2s ease-in-out;

		svg {
			fill: $c_main;
			transition: all .2s ease-in-out;
			display: inline-block;
			vertical-align: middle;
			margin-left: 18px;
		}

		&:hover {
			color: $c_second;

			svg {
				fill: $c_second;
			}
		}
	}

	&-itm {
		position: relative;
		display: flex;
		justify-content: space-between;
		text-decoration: none;
		color: $c_gray;
		padding: 23px 0 17px;
		z-index: 1;
		gap: 20px;

		&:after {
			content: '';
			position: absolute;
			bottom: 0;
			left: -30px;
			right: -30px;
			border-bottom: 1px solid rgba(179, 179, 179, 0.3);
		}

		&:last-child:after {
			display: none;
		}

		&:before {
			content: '';
			@include fill();
			background: linear-gradient(89.93deg, #FFFFFF 0.05%, #EFEFFF 52.6%, #FFFFFF 99.94%);
			z-index: -1;
			opacity: 0;
			transition: opacity .2s ease-in-out;
		}

		&:hover {
			&:before {
				opacity: 1;
			}

			.list-block-itm__info__title {
				color: $c_main;
			}
		}

		&-file-link {
			text-decoration: none;
			color: $c_gray;
			display: flex;
			gap: 5px;
			align-items: center;
			cursor: pointer;

			svg {
				fill: $c_gray_bg;
			}

			&:hover {
				color: $c_second;

				svg {
					fill: $c_second;
				}
			}
		}

		&__info {
			&__title {
				transition: color .3s ease-in-out;
				margin-bottom: 9px;
				display: block;
				text-decoration: none;
				color: inherit;
			}

			&__file {
				display: flex;
				align-items: center;
				gap: 9px;
				color: $c_gray_bg;
				font-size: $fs_small;
				font-weight: 300;

				svg {
					position: relative;
					top: -2px;
				}

				.sep {
					height: 10px;
					width: 1px;
					background: $c_gray_bg;
				}
			}
		}

		&__date {
			color: $c_gray_bg;
			font-weight: 300;
			font-size: $fs_small;
			flex-shrink: 0;
			white-space: pre-line;
			text-align: center;

		}
	}
}

.main-analytic {
	margin: 52px 0 70px;

	.title {
		margin-bottom: 0px;
		margin-left: 40px;
		color: var(--c_main_dark);
		font-size: var(--fs_title_mid);
	}

	.btn {
		width: 100%;
	}

	&-desc {
		font-weight: 300;
		padding: 0 40px;
		font-size: $fs_small;
		margin-top: 33px;
	}
}

.intro{
	margin: 70px 0;
}

.main-chart-cols {
	display: flex;
	margin-top: 40px;
}

.main-chart-itm {
	padding: 0 40px;
	width: 50%;
	box-sizing: border-box;
	color: rgba(111, 90, 173, 0.3);
	text-align: center;

	&__title {
		font-weight: 300;
		color: $c_gray;
		margin-bottom: 15px;
	}

	.tabs-head {
		margin-bottom: 15px;
	}

	.apexcharts-canvas svg{
		overflow: visible;
	}
}

@media all and (max-width: 1200px) {
	.main-info-itm__num {
		width: 100%;
	}
}