.tooltip 
  position: relative
  display: inline-block


.tooltip .tooltiptext 
  visibility: hidden
  width: 140px
  background-color: #555
  color: #fff
  text-align: center
  border-radius: 6px
  padding: 5px
  position: absolute
  z-index: 1
  bottom: 150%
  left: 50%
  margin-left: -75px
  opacity: 0
  transition: opacity 0.3s


.tooltip .tooltiptext::after 
  content: ""
  position: absolute
  top: 100%
  left: 50%
  margin-left: -5px
  border-width: 5px
  border-style: solid
  border-color: #555 transparent transparent transparent


.tooltip:hover .tooltiptext 
  visibility: visible
  opacity: 1


.invite
  input
    width: 550px

.invited_users
  &__list
    display: grid
    margin: 2rem 0
    grid-template-columns: repeat(6, 1fr) max-content
    gap: 1rem 0
    > div > div
      padding: 0 1rem 1rem
      border-bottom: 1px solid var(--c_gray_bg)
      display: flex
      align-items: center
      font-weight: 300

  &__wrapper
    display: contents

  &--bold
    font-weight: bold !important

.apexcharts-canvas
  svg
    overflow: visible
.table_chart
  margin: -2rem 0