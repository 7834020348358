@font-face {
	font-family: 'Intro';
	src: url('../fonts/Intro-Regular.eot');
	src: local('Intro Regular'), local('Intro-Regular'),
	url('../fonts/Intro-Regular.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Intro-Regular.woff') format('woff'),
	url('../fonts/Intro-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Intro';
	src: url('../fonts/Intro-Bold.eot');
	src: local('Intro Bold'), local('Intro-Bold'),
	url('../fonts/Intro-Bold.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Intro-Bold.woff') format('woff'),
	url('../fonts/Intro-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Intro';
	src: url('../fonts/Intro-Light.eot');
	src: local('Intro Light'), local('Intro-Light'),
	url('../fonts/Intro-Light.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Intro-Light.woff') format('woff'),
	url('../fonts/Intro-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}
