.platforms-table-list
	&__item
		padding: 1rem 1.5rem
		grid-template-columns: 2rem 15rem 15rem 10rem 7rem 5rem 8rem 5rem
		//margin: 1rem 0
		justify-items: stretch
		border-bottom: 1px solid transparentize($c_gray_bg, .7)

		&:hover
			background: linear-gradient(90deg, rgba(239,239,255,0) 0%, rgba(245,245,255,1) 10%, rgba(245,245,255,1) 90%, rgba(239,239,255,0) 100%)
			.line-fade
				background: linear-gradient(90deg, rgba(239, 239, 255, 0) 0%, rgb(245, 245, 255) 10%, rgb(245, 245, 255) 90%, rgba(239, 239, 255, 0) 100%)
				&:after
					background: linear-gradient(to right, rgba(239,239,255,0), rgba(245,245,255,1))
		.line-fade:hover
			width: max-content
			background: #fff
			outline: 5px solid #fff
			z-index: 1
			border-radius: 8px
			&:after
				display: none

		& > *:nth-last-child(1), & > *:nth-last-child(2)
			text-align: center
		& *
			font-weight: 300
		& > *:nth-child(2)
			font-weight: 400
		& b
			font-weight: 700



	&__head-item
		background: var(--c_select_bg)
		border: none
		border-radius: .5rem
		justify-items: stretch
		*
			font-weight: 400 !important
		&:hover
			background: var(--c_select_bg)

.loader_container
	display: flex
	align-items: center
	justify-content: center
	width: 100%
	min-height: 200px

.platforms_table_info
	margin-bottom: 2rem

.js-show-selected
	cursor: pointer

.modal_table
	max-height: 60vh
	overflow-x: auto

.saved_platforms_list__table
	.grid-table__row
		padding: 1.5rem 1.6rem
		grid-template-columns: 1fr 10rem 10rem 8rem
		align-items: center
		border-bottom: 1px solid transparentize($c_gray_bg, .7)
		&:last-child
			border-bottom: none

		.title
			margin: 0

	.platforms-table-list__head-item
		border: none
		padding: .8rem 1.6rem

.grid-table__row
	.cross
		width: 20px
		height: 20px
		&:before, &:after
			background: var(--c_gray_bg)
	.flex_row
		align-items: center
		gap: .8rem

	.js-delete
		cursor: pointer


.js-show-detail
	cursor: pointer

.selected_platform_list
	font-size: var(--fs_title)
	color: var(--c_main_dark)
	--cross_color: var(--c_gray_bg)
	display: flex
	gap: 2.3rem
	padding: 1.1rem 1.25rem
	border: 1px solid var(--c_second)
	border-radius: .5rem
	width: max-content
	align-items: center
	font-weight: 700

.platforms_page
	overflow: hidden

.platforms-table-list
	overflow-x: auto
	padding-bottom: 20px

.filter-blocks
	border-bottom: 1px solid var(--c_select_bg)
	--gap_t_b: 0
	--gap_l_r: 0

.filter-block
	padding: 50px 0 50px 50px
	&:first-child
		padding: 50px 50px 50px 0

	.smart-search-input
		margin-bottom: 0

	.smart-search-block
		margin-bottom: 0

	.filter-block-list
		margin-top: 27px

	.source-block-list
		margin-top: 27px

	&:not(:first-child)
		border-left: 1px solid var(--c_select_bg)
