.detail-material-itm
  &.is-new
    border: 1px solid var(--c_second)
    padding: 10px
    border-radius: 10px

.detail-material-itm-content__info__link
  max-width: 300px
  span
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis