$dark_shadow: -20px 22px 50px rgba(0, 0, 0, 0.4);

body.theme-dark {
	background: $c_gray;

	.loader {
		background: $c_d_gray;
	}

	.header {
		background: $c_d_gray;
		color: #fff;
		box-shadow: $dark_shadow;
	}

	.header__logo {
		filter: grayscale(1) invert(1);
	}

	.main-chart-itm__title {
		color: #fff;
	}

	.wrapper {
		background: #000;
		color: #fff;
	}

	.tabs-head__itm {
		color: $c_light_gray;

		&:hover, &.active {
			color: $c_d_gray;
		}
	}

	.main-info-itm {
		color: #fff;
		background: $c_d_gray;

		&__bg {
			opacity: 0.2;
			filter: invert(1);
		}

		&__ico svg {
			fill: #fff;
		}
	}

	.btn {
		box-shadow: $dark_shadow;
		background: $c_mid_gray;

		&:before {
			background: $c_mid_gray;
		}
	}

	.list-block {
		background: $c_d_gray;
		box-shadow: $dark_shadow;

		&-itm {
			color: $c_light_gray;

			&:before {
				display: none;
			}

			&:hover {
				.list-block-itm__info__title {
					color: $c_second;
				}
			}
		}
	}

	.list-block__link:not(:hover) {
		color: $c_light_gray;

		svg {
			fill: $c_light_gray;
		}

	}

	.c-main-dark {
		color: $c_light_gray;
	}

	.remember-list__itm {
		background: $c_d_gray;
		color: $c_light_gray;

		&:hover{
			background: $c_second;
		}
	}

	.source-search-list-itm{
	color: $c_second;
	}

	.input-wrap {
		input {
			background: $c_d_gray;
			color: #fff;
		}

		&__label {
			color: $c_gray_bg;
		}
	}

	.smart-search-input {
		svg {
			filter: invert(1);
		}
	}

	.smart-search-list {
		background: $c_d_gray;
		box-shadow: $dark_shadow;

		&-itm{
			&:hover{
				color: $c_second;
			}
		}
	}

	.header-user {
		color: $c_light_gray;

		&:after {
			border-top-color: $c_light_gray;
		}
	}

	.header-user-list {
		background: $c_d_gray;
		box-shadow: $dark_shadow;
	}

	.header-setting {
		background: $c_d_gray;
		color: $c_light_gray;
		box-shadow: $dark_shadow;

		&-itm__title {
			color: $c_light_gray;
		}
	}

	.detail-section-checkbox input:checked ~ .detail-section-checkbox__check {
		background: $c_d_gray;
	}

	.detail-section-checkbox__check:hover {
		background: $c_mid_gray;
	}

	.chart-section {
		background: $c_d_gray;
		color: $c_light_gray;
		box-shadow: $dark_shadow;
	}

	.apexcharts-legend-text {
		color: $c_light_gray !important;
	}

	text {
		fill: $c_light_gray !important;
	}

	.modal-content {
		background: #fff;
		box-shadow: $dark_shadow;
		color: var(--c_d_gray);
	}

	.item-with-menu-list {
		background: $c_d_gray;
		box-shadow: $dark_shadow;
	}

	.detail-material-footer__date .detail-material-calendar {
		box-shadow: $dark_shadow;
	}

	.detail-material-itm-content__info__link, .detail-references__num__val, .table-link {
		color: $c_second;
	}

	.detail-material-footer__itm {
		color: #fff;
	}

	.detail-material-footer__itm svg {
		fill: #fff;
	}

	.apexcharts-area {
		//filter: grayscale(1);
	}


	.apexcharts-canvas svg{
		//filter: grayscale(1);
	}

	.apexcharts-tooltip{
		color: #000;
	}

	.source-block-list-itm input:checked ~ .source-block-list-itm__name {
		background: $c_d_gray;
	}

	.source-block-list-itm__name {
		background: $c_gray;
	}

	.more-btn.orange{
		color: $c_light_gray;

		svg{
			fill: $c_light_gray;
		}

		&:hover{
			color: $c_second;

			svg{
				fill: $c_second;
			}
		}
	}

	.detail-section-checkbox input:checked ~ .detail-section-checkbox__check:after{
		filter: grayscale(1) brightness(3);
	}

	.remember-block__link a, .header-link a{
		color: #fff;

		&:hover{
			color: var(--c_second);
		}
	}

	.search-phrases__item{
		color: #fff;
	}
	.c-main{
		color: #fff;
	}
}
