//шрифты
.title
	font-size: var(--fs_title)
	margin-bottom: 1rem

.title--big
	font-size: var(--fs_big_title)

.title--small
	font-size: var(--fs_title-small)

.title--middle
	font-size: var(--fs_title_mid)

.t_small
	font-size: var(--fs_small)

.t_normal
	font-size: var(--fs_normal)

.text
	p
		margin: 0.5rem 0

.text--modal
	font-size: 1.3rem

.text--capitalize
	text-transform: capitalize

.text--green
	color: var(--c_salad_border)

.text--dark_blue
	color: var(--c_main_dark)

.text--blue
	color: var(--c_main)

.text--gray
	color: var(--c_gray)

.c-light-gray, .text--light_gray
	color: var(--c_gray_bg)

.text--as_title
	@extend .text--dark_blue
	font-size: var(--fs_title)
	font-weight: 700

b
	font-weight: 700

//флекс бокс
.flex_row
	display: flex
	align-items: center

	&--sp_between
		justify-content: space-between

	&--sp_center
		justify-content: center

	&--wrap
		flex-wrap: wrap

	&--to_top
		align-items: flex-start

	&--to_left
		justify-content: flex-start

	&--stretch
		justify-content: stretch

	&--gap_w_normal
		column-gap: 1rem

	&--gap_w_double
		column-gap: 2rem

	&--gap_h_normal
		row-gap: 1rem

	&--gap_h_double
		row-gap: 2rem


//grid flow
\:root
	--gap_t_b: 2rem
	--gap_l_r: 2rem
.grid
	display: grid
	gap: var(--gap_t_b) var(--gap_l_r)

//text
.text
	&--small
		font-size: var(--fs_small)

	&--normal
		font-size: var(--fs_normal)

	&--light
		font-weight: 300

.flex_row
	.input-wrap
		margin-right: 1.5rem

//таблица на гридах
.grid-table
	> *
		display: grid
		grid-auto-flow: column
		grid-auto-columns: max-content
		grid-gap: 20px
		justify-content: space-between
		align-content: stretch
		align-items: center

		.centred
			text-align: center
	&--min-columns > *
		grid-auto-columns: min-content

.line-fade
	white-space: nowrap
	position: relative
	overflow: hidden

	&:after
		content: ''
		position: absolute
		top: 0
		bottom: 0
		right: 0
		width: 2rem
		background: linear-gradient(to right, transparentize(#F9F9FF, 1), #F9F9FF)

//пагинация
.table-pagination
	list-style: none
	display: flex
	align-items: center
	justify-content: center
	margin: 3.5rem 0 2rem 0
	gap: 5px

	li
		cursor: pointer
		padding: .3rem .6rem
		border-radius: 0.2rem
		font-size: var(--fs_small)
		font-weight: 300

		&.active, &:hover
			background: var(--c_select_bg)

//строчный селект
.inline_select, .pretty_select
	padding: 0.75rem 1.2rem
	border: 1px solid #B3B3B3
	border-radius: .5rem
	background: #fff
	font-weight: inherit
	color: inherit
	font-size: var(--fs_normal)
	margin-left: 0.8rem
	font-family: inherit
	appearance: inherit
	cursor: pointer
	outline: none !important

	option
		padding: 0.75rem 1.2rem
		display: inline-block
		cursor: pointer

\:root
	--dd_arrow_width: 6px

.pretty_select
	$blockName: &
	appearance: initial
	margin: 0
	padding: 0
	position: relative
	box-sizing: border-box
	select
		display: none

	&:after
		content: ''
		top: 50%
		margin-top: -2.5px
		display: block
		position: absolute
		right: 1rem
		border: var(--dd_arrow_width) solid transparent
		border-top: var(--dd_arrow_width) solid #666

	&__list
		position: absolute
		top: 100%
		left: 0
		width: 100%
		padding: 0
		box-sizing: border-box
		border: 1px solid #B3B3B3
		border-radius: .5rem
		background: #fff
		box-shadow: 0px 10px 21px rgba(111, 90, 173, 0.2)
		z-index: 100
		overflow: hidden
		opacity: 0
		transition: .5s
		pointer-events: none

	&__selected
		min-height: 1rem
		padding: 0.75rem 1.2rem

	&__list_item
		padding: 0.75rem 1.2rem
		border-bottom: 1px solid transparentize($c_gray_bg, .7)

		&:hover
			background: var(--c_select_bg)

	&.active
		#{$blockName}
			&__list
				opacity: 1
				pointer-events: all



//вертикальный разделитель
.ver_hr
	width: 0
	border-right: 1px solid #D9D9D9
	height: 2.2rem
	vert-align: middle
	margin: 0 2rem


//модалка
.modal_holder
	position: fixed
	top: 0
	left: 0
	right: 0
	bottom: 0
	transition: .5s
	opacity: 0
	pointer-events: none
	display: flex
	align-items: center
	justify-content: center
	z-index: 100

	.modal_bg
		background: rgba(65, 39, 121, 0.20)
		backdrop-filter: blur(0)
		position: absolute
		top: 0
		left: 0
		right: 0
		bottom: 0
		transition: .5s

	&.active
		opacity: 1
		pointer-events: all
		.modal_bg
			backdrop-filter: blur(8.5px)

	.modal_content
		background: var(--c_light_gray)
		padding: 3rem
		border-radius: 1rem
		box-shadow: -20px 22px 50px rgba(111, 90, 173, 0.48)
		position: relative

		&--small
			width: 40rem

	.cross
		width: 24px
		height: 24px
		color: var(--c_main)
		&:before, &:after
			background: var(--c_main)



	.modal_close
		color: var(--c_main)
		display: flex
		justify-content: flex-end
		align-items: center
		gap: 1.5rem
		margin-bottom: 2.3rem
		&:hover
			color: var(--c_main_dark)
			& .cross:before, & .cross:after
				background: var(--c_main_dark)

		> *
			cursor: pointer

.error
	color: var(--c_red)
\:root
	--cross_color: #fff
.cross
	width: 1rem
	height: 1rem
	overflow: hidden
	position: relative

	&:before, &:after
		content: ''
		background: var(--cross_color)
		height: 2px
		width: 1rem
		transform: rotate(45deg)
		top: 50%
		margin-top: -1px
		left: 0
		position: absolute

	&:before
		transform: rotate(-45deg)
	&.cross--text
		width: auto
//кнопка как текст
.text_button
	font: inherit
	background: transparent
	border: none
	outline: none
	cursor: pointer
	padding: 0

.hidden_calendar
	opacity: 0
	pointer-events: none
	position: absolute
	bottom: 100%
	left: 100%
	transition: .5s
	z-index: 100

	&.active
		opacity: 1
		pointer-events: all

.calendar_wrapper
	position: relative
	cursor: pointer

//тройной переключатель
\:root
	--toggle_size: 30px

.tri-state-toggle
	background: transparent
	border: 1px solid rgba(165,170,174,0.25)
	border-radius: 24px
	overflow: hidden
	display: inline-flex
	flex-direction: column
	transition: all 500ms ease

.tri-state-toggle-button
	border-radius: 50%
	height: var(--toggle_size)
	width: var(--toggle_size)
	display: flex
	align-items: center
	justify-content: center
	background-color: transparent
	margin: 2px
	color: #727C8F
	cursor: pointer
	border: 1px solid #00000011
	-webkit-transition: all 0.5s ease-in-out
	-moz-transition:    all 0.5s ease-in-out
	-ms-transition:     all 0.5s ease-in-out
	-o-transition:      all 0.5s ease-in-out
	transition:         all 0.5s ease-in-out

.tri-state-toggle-button:hover
	border: 1px solid #00000033


.tri-state-toggle-button.active
	background: #8165CC
	border: 1px solid rgba(207,207,207,0.6)
	box-shadow: 0 8px 16px 0  rgba(80, 56, 236, 0.3)
	color: #6744B1
	font-weight: 500
	transition: all .5s ease-in

.tri-state-toggle-button:focus
	outline: none

.tri-state-toggle__lables
	margin-left: 1rem
	font-size: var(--fs_title-small)
	color: var(--c_main_dark)

	p
		line-height: var(--toggle_size)


.text-btn
	background: transparent
	border: none
	padding: 0
	font-weight: 400
	color: var(--c_main)
	text-decoration: none
	cursor: pointer
	&:hover
		text-decoration: underline
*[aria-busy="true"]
	opacity: 0.5
	cursor: wait
	*
		pointer-events: none


/* To hide the checkbox */
.checkboxInput
	display: none

.toggleSwitch
	display: flex
	align-items: center
	justify-content: center
	position: relative
	width: 50px
	height: 30px
	background-color: #5252525c
	border-radius: 20px
	cursor: pointer
	transition-duration: .2s

.toggleSwitch::after
	content: ""
	position: absolute
	height: 10px
	width: 10px
	left: 5px
	background-color: transparent
	border-radius: 50%
	transition-duration: .2s
	box-shadow: 5px 2px 7px rgba(8, 8, 8, 0.26)
	border: 5px solid white

.checkboxInput:checked+.toggleSwitch::after
	transform: translateX(100%)
	transition-duration: .2s
	background-color: white


/* Switch background change */
.checkboxInput:checked+.toggleSwitch
	background: rgb(255, 85, 85)
	transition-duration: .2s

svg
	.active
		fill-opacity: .7
.delition_mode_wrapper
	margin-left: 50px

.filter-block-list
	&_wrapper
		overflow: hidden
		transition: all .5s ease
		&.active
			height: max-content

	&_expander
		padding: 1rem 0
		cursor: pointer
		color: var(--c_main)
		display: flex
		vertical-align: middle
		width: max-content
		align-items: center
		gap: 20px
		transition: all .3s
		svg
			fill: var(--c_main)
			transform: rotate(90deg)
			transition: all .3s

		.hided
			display: block
		.showed
			display: none

		&:hover
			color: var(--c_second)
			svg
				fill: var(--c_second)

		&.active
			svg
				transform: rotate(-90deg)
			.hided
				display: none
			.showed
				display: block
.main-content .react-loading-skeleton
	--base-color: var(--c_gray_bg)
	

.control_button
	width: 35px
	height: 35px
	position: relative
	display: flex
	align-items: center
	justify-content: center
	background-color: var(--c_main)
	opacity: .5
	border-radius: 50%
	cursor: pointer
	transition-duration: .3s
	box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.13)
	border: none
	&.del
		opacity: 1

.bell 
	width: 25px

.bell path 
	fill: white

.control_button
	&:hover
		background-color: var(--c_salad_border)
		opacity: 1

	&:hover .bell
		animation: bellRing 0.9s both

	&.del:hover
		background-color: var(--c_red_border)

/* bell ringing animation keyframes*/
@keyframes bellRing 
	0%,
	100%
		transform-origin: center

	15%
		transform: rotateZ(30deg)

	30%
		transform: rotateZ(-30deg)

	45%
		transform: rotateZ(10deg)

	60%
		transform: rotateZ(-10deg)

	75%
		transform: rotateZ(5deg)

.control_button:active
	transform: scale(0.8)


.mb
	&__none
		margin-bottom: 0 !important
	&__normal
		margin-bottom: 1rem !important
	&__double
		margin-bottom: 2rem !important
.mt
	&__none
		margin-top: 0 !important
	&__normal
		margin-top: 1rem !important
	&__double
		margin-top: 2rem !important

.w
	&--min-content
		width: min-content

*[aria-disabled="true"]
	cursor: wait

.formError
	color: var(--c_red_border)