.editBtn 
  width: 30px
  height: 30px
  border-radius: 20px
  border: none
  background-color: var(--c_main)
  display: flex
  align-items: center
  justify-content: center
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.123)
  cursor: pointer
  position: relative
  overflow: hidden
  transition: all 0.3s

.editBtn::before 
  content: ""
  width: 200%
  height: 200%
  background-color: var(--c_second)
  position: absolute
  z-index: 1
  transform: scale(0)
  transition: all 0.3s
  border-radius: 50%
  filter: blur(10px)

.editBtn:hover::before 
  transform: scale(1)

.editBtn:hover 
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.336)


.editBtn svg 
  height: 15px
  fill: white
  z-index: 3
  transition: all 0.2s
  transform-origin: bottom

.editBtn:hover svg 
  transform: rotate(-15deg) translateX(5px)

.editBtn::after 
  content: ""
  width: 13px
  height: 2px
  position: absolute
  bottom: 6px
  left: -5px
  background-color: white
  border-radius: 2px
  z-index: 2
  transform: scaleX(0)
  transform-origin: left
  transition: transform 0.5s ease-out

.editBtn:hover::after 
  transform: scaleX(1)
  left: 0px
  transform-origin: right
