.auth-page {
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;

	&-content {
		display: flex;
		gap: 35px;
		padding: 40px 0;

		.title{
			margin-bottom: 38px;

			&.mb-10{
				margin-bottom: 10px;
			}
		}

		.nice-form{
			margin-top: 39px;

			.btn{
				margin-top: 35px;
			}
		}


		&__right{
			max-width: 710px;
		}
	}

	&-text{
		margin-bottom: 38px;
	}

	&-desc{
		max-width: 515px;
	}
}
.auth_footer{
	position: absolute;
	bottom: 20px;
	left: 50%;
	transform: translateX(-50%);
	color: #ddd;
	font-weight: 300;
	font-size: var(--fs_small);
}