.facet{
  &_item{
    width: 100%;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr 5rem 2.5rem;
    border-bottom: 1px solid var(--c_light_gray_border);
    padding: 1.2rem .8rem;

    &:last-child{
      border: none;
    }

    .blank_ico{
      stroke: var(--c_gray_bg);
      fill: var(--c_gray_bg);
    }

    > div{
      &:last-child, &:nth-last-child(2){
        text-align: right;
        color: var(--c_gray_bg);
      }
    }
  }
}