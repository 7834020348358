.query-detail-section {
	display: flex;
	gap: 21px;
	margin-bottom: 27px;

	&__title {
		margin-bottom: 36px;
	}

	&__content {
		flex: 1;
		padding: 20px 0;
	}

	&__aside {
		width: 80px;
		flex-shrink: 0;
	}

	&.center {
		padding-top: 20px;
		align-items: flex-end;
		margin-bottom: 15px;

		.query-detail-section__content {
			padding: 0;
		}
	}
}

.query-detail-section-content {
	padding-right: 40px;
}

.avatar {
	position: relative;
	width: 100%;
	padding-bottom: 100%;
	border-radius: 8px;

	&:hover, &.hover, &.focus {
		.avatar-hover {
			opacity: 1;
		}

		.avatar__image {
			opacity: 0.2;
		}

		.avatar__text {
			opacity: 0;
		}
	}

	&__border {
		@include fill();
		border: 2px dashed $c_gray_bg;
		border-radius: 8px;
	}

	&__text {
		position: absolute;
		bottom: 16px;
		left: 16px;
		right: 16px;
		text-align: center;
		transition: opacity .3s ease-in-out;
	}

	&__image {
		@include fill(19px);
		display: flex;
		align-items: center;
		justify-content: center;
		transition: opacity .3s ease-in-out;

		img {
			width: 100%;
			height: 100%;
			object-position: center;
			object-fit: contain;
		}
	}

	&-hover {
		@include fill();
		display: flex;
		flex-direction: column;
		justify-content: center;
		transition: opacity .3s ease-in-out;
		opacity: 0;

		&__top {
			padding: 10px 30px 10px;
			text-align: center;
			flex: 2;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}

		&__bot {
			padding: 10px 30px;
			border-top: 2px dashed $c_gray_bg;
			text-align: center;
			flex: 1;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			input[type=file] {
				position: absolute;
				opacity: 0;
				pointer-events: none;
			}
		}
	}
}

.detail-section-checkbox {
	position: relative;
	display: block;
	width: 100%;
	height: 100%;

	input {
		opacity: 0;

		&:checked {
			~ .detail-section-checkbox__check {
				background: #EFEFFF;
				border-color: #EFEFFF;

				&:after {
					opacity: 1;
					transform: scale(1);
				}
			}
		}
	}

	&__check {
		@include fill();
		border: 1px solid #D9D9D9;
		border-radius: 8px;
		transition: all .3s ease-in-out;

		&:hover {
			background: #EFEFFF;
		}

		&:after {
			content: "";
			position: absolute;
			width: 35px;
			height: 40px;
			top: 50%;
			left: 50%;
			margin-top: -20px;
			margin-left: -17.5px;
			background: url("../ico/check.svg");
			background-size: contain;
			opacity: 0;
			transform: scale(0.5);
			transition: all .3s ease-in-out;
			transform-origin: bottom center;
		}
	}
}


.chart-section {
	background: #fff;
	padding: 65px 30px;
	box-shadow: 0px 10px 21px rgba(111, 90, 173, 0.2);
	border-radius: 8px;
	margin: -20px 0;

	&__itm {
		max-width: 814px;
		margin: 0 auto;
	}

	&__title {
		margin-bottom: 22px;
	}

}

.query-detail-desc-table {
	margin: 20px 0;

	td {
		padding: 15px;
		white-space: pre-line;

		&:first-child {
			padding-left: 0;
		}

		&:last-child {
			padding-right: 0;
			word-break: break-word;
		}
	}
}

.text-content {
	width: 750px;
	max-width: 100%;
}

.title-margin {
	margin-bottom: 10px;
}

.query-detail-sep {
	position: relative;
	margin-right: 100px;

	&:before {
		content: '';
		position: absolute;
		top: -14px;
		left: 0;
		right: 0;
		border-bottom: 1px solid rgba(179, 179, 179, 0.3);
	}
}

.link-itm-table {
	margin-top: -20px;

	th {
		font-size: $fs_small;
		font-weight: 300;

		&:first-child {
			text-align: left;
		}
	}

	td {
		min-width: 190px;
		text-align: center;
		padding: 10px 0;

		svg {
			display: inline-block;
			margin-right: 10px;
			fill: $c_gray_bg;
		}

		&:first-child {
			width: 100%;
			text-align: left;
		}
	}
}

.table-link {
	text-decoration: none;
	color: $c_main;

	&:hover {
		color: $c_second;
	}
}

.tabs {
	.title {
		margin-bottom: 20px;
	}

	.title-flex .title {
		margin-bottom: 0;
	}

	&-head {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;
		list-style: none;

		&__itm {
			padding: 11px 10px;
			border-radius: 8px;
			cursor: pointer;
			transition: all .1s ease-in-out;
			text-align: center;
			min-width: 83px;
			box-sizing: border-box;

			&.active, &:hover {
				background: $c_select_bg;
			}

			&.active {
				cursor: auto;
				color: #666666;
			}
		}
	}

	&-content {
		padding: 0 40px;

		&__itm {
			display: none;
			padding-top: 20px;

			&.active {
				display: block;
			}
		}

		.link-itm-table {
			margin: 60px 0;
		}
	}
}

.detail-material-list {
	margin-top: 58px;
}

.detail-material-itm {
	display: flex;
	margin-bottom: 50px;
	gap: 17px;

	&-checkbox {
		position: relative;
		top: -3px;
	}

	&-content {
		flex: 1;

		&__top {
			display: flex;
			justify-content: space-between;
			margin-bottom: 6px;

			&__link {
				position: relative;
				color: $c_gray_bg;
				text-decoration: none;

				&:before {
					content: '';
					@include fill(-10px)
				}

				&__text {
					opacity: 0;
					transition: all .3s ease-in-out;
				}

				svg {
					position: absolute;
					top: 0;
					right: 0;
					transform: translateY(-20%);
					transition: all .3s ease-in-out;
					fill: $c_gray;
				}

				&:hover {
					color: $c_main;

					.detail-material-itm-content__top__link__text {
						opacity: 1;
					}

					svg {
						right: 105%;
						fill: $c_main;
					}
				}
			}
		}

		&__info {
			display: flex;
			gap: 60px;
			align-items: center;
			margin-bottom: 16px;

			&__link {
				color: $c_main;
				text-decoration: none;
				font-weight: 300;
				font-size: $fs_small;
				transition: color .2s ease-in-out;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 1;
				overflow: hidden;
				word-break: break-all;

				&:hover {
					color: $c_second;
				}
			}

			&__date {
				display: flex;
				align-items: center;
				font-weight: 300;
				font-size: $fs_small;

				span {
					display: inline-block;
					vertical-align: middle;

					&:not(:first-child) {
						position: relative;
						margin-left: 20px;
						padding-left: 20px;

						&:before {
							content: '';
							position: absolute;
							left: 0;
							top: 50%;
							margin-top: -7px;
							height: 12px;
							width: 1px;
							background: $c_gray;
						}
					}
				}
			}
		}

		&__text {
			font-size: $fs_small;
			font-weight: 300;
			line-height: 1;
			overflow: hidden;
			transition: all .3s ease-in-out;
			word-break: break-word;
			white-space: break-spaces;

			&.line-clamp {
				display: -webkit-box;
				-webkit-line-clamp: 3;
				-webkit-box-orient: vertical;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			&__inner{
				p{
					margin: 20px 0  7px 0;

					&:first-child{
						margin-top: 0;
					}
				}
				table {
					border-collapse: collapse;
					margin-bottom: 20px;
					max-width: 100%;

					td, th {
						padding: 5px;
						border: 1px solid
					}
				}

				ul li{
					list-style: disc;
					margin-left: 16px;
				}
			}
		}
	}
}

.detail-material-footer {
	margin-top: 60px;
	display: flex;
	align-items: center;
	gap: 100px;

	&__itm {
		border: none;
		outline: none;
		background: none;
		display: flex;
		align-items: center;
		gap: 14px;
		color: $c_main;
		cursor: pointer;
		transition: color .2s ease-in-out;

		.react-datepicker-wrapper {
			position: absolute;
			bottom: 0;
			left: 0;

			input {
				position: absolute;
				bottom: 0;
				left: 0;
				opacity: 0;
				pointer-events: none;
			}
		}

		&:hover {
			color: $c_second;

			svg {
				fill: $c_second;
			}
		}

		svg {
			fill: $c_main;
			transition: all .2s ease-in-out;

			&.rotate {
				transform: rotate(90deg);
			}
		}
	}

	&__date {
		position: relative;

		.detail-material-calendar {
			position: absolute;
			left: 0;
			top: 100%;
			margin-top: 20px;
			z-index: 2;
			box-shadow: -20px 22px 50px rgba(111, 90, 173, 0.48);
			opacity: 0;
			transform: translateY(-10px);
			transition: all .3s ease-in-out;
			pointer-events: none;
		}

		&.active {
			.detail-material-calendar {
				opacity: 1;
				transform: translateY(0);
				pointer-events: auto;
			}
		}
	}
}

.detail-references {
	display: flex;
	gap: 80px;

	&__right {
		flex: 1;
	}

	&__num {
		margin-top: 53px;

		&__val {
			font-size: $fs_big_title;
			color: $c_main_dark;
			font-weight: 700;
		}

	}


	.tabs-head__itm {
		padding-left: 18px;
		padding-right: 18px;
	}
}

.detail-export {
	padding-top: 45px;
	border-top: 1px solid rgba(179, 179, 179, 0.3);
	margin-bottom: 80px;

	&__btn {
		display: block;
		width: 515px;
		max-width: 100%;
		margin: 0 auto;
	}
}

.modal-export-form {
	display: flex;
	align-items: center;
	gap: 20px;

	.input-wrap {
		margin-bottom: 0;
	}

	.btn {
		flex-shrink: 0;
	}
}

.tag-cloud-block {
	text-align: center;

	svg {
		margin: 20px auto;
	}
}

.item-with-menu {
	position: relative;
	display: flex;
	gap: 10px;

	&-wrap {
		display: inline-block;
		position: relative;
	}

	&:hover {
		z-index: 2;
	}

	&__btn {
		svg {
			position: relative;
			z-index: 1;
		}

		&:hover {
			.item-with-menu-list {
				pointer-events: auto;
				opacity: 1;
			}

			+ .item-with-menu__name {
				pointer-events: none;
			}
		}
	}

	&-list {
		list-style: none;
		position: absolute;
		left: -14px;
		top: -14px;
		right: -14px;
		background: #fff;
		padding: 40px 32px 10px 40px;
		box-shadow: 0px 10px 21px rgba(111, 90, 173, 0.2);
		border-radius: 8px;
		pointer-events: none;
		opacity: 0;
		transition: all .3s ease-in-out;
		min-width: 300px;

		li {
			padding: 12px 7px;
			border-bottom: 1px solid rgba(179, 179, 179, 0.3);
			cursor: pointer;

			&:last-child {
				border-bottom: none;
			}

			&:hover {
				color: $c_second;
			}

			&.nohover {
				cursor: initial;
				color: $c_second;
			}

			a{
				color: inherit;
				text-decoration: none;
			}
		}
	}

	&__name {
		position: relative;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		overflow: hidden;
	}
}

.tag-cloud {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	gap: 5px 20px;
	margin-top: 20px;
}

.remove-dossier-concept {
	color: $c_gray_bg;

	.cross {
		display: inline-block;
		cursor: pointer;

		&:before, &:after {
			background-color: $c_gray_bg;
			transition: all .3s;
		}

		&:hover {

			&:before, &:after {
				background-color: $c_second;
			}
		}
	}
}

.add-dossier-concept {
	cursor: pointer;
	transition: all .3s;

	&:hover {
		color: $c_second;
	}
}