.users_pager_wrapper
  width: 100%
.users_pager
  margin: 2rem 0
  list-style: none
  li
    cursor: pointer
    &:hover
      color: var(--c_second)
    &.active
      cursor: default
      &:hover
        color: inherit

.user_edit
  select
    border: 1px solid var(--c_gray_bg)
    height: 43px
    width: 100%
    border-radius: 8px
    background: #fff
    font-family: "Intro"
    line-height: 43px
    padding:  0 15px
    font-size: 1rem

  input
    font-size: 1rem

.users-table
  display: grid
  grid-template-columns: repeat(6, 1fr) max-content max-content max-content
  grid-gap: 1rem 0
  --cross_color: var(--c_gray_bg)
  &__item
    padding: 0 1rem 1rem 1rem
    border-bottom: 1px solid var(--c_gray_bg)
    font-weight: 300
    &--head
      font-weight: 700

  .date
    text-align: center
    justify-content: center